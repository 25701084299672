// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"ERUv91L3rnlRFwACWh4Zl"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

const NODE_ENV = process.env.NODE_ENV;

if (NODE_ENV !== "development") {
	Sentry.init({
		dsn: "https://5913aa218b50d6b2f7c5bbeeb1c32d5b@o4507532377260032.ingest.de.sentry.io/4508020991590480",

		// Add optional integrations for additional features
		integrations: [Sentry.replayIntegration()],

		// Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
		tracesSampleRate: 1,

		// Define how likely Replay events are sampled.
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// Define how likely Replay events are sampled when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// Setting this option to true will print useful information to the console while you're setting up Sentry.
		debug: false,
	});
}
