import { QueryClient } from "@tanstack/react-query";

const defaultQueryConfig = {
	refetchOnWindowFocus: false,
	staleTime: 300000,
};

export const queryClient = new QueryClient({
	defaultOptions: { queries: defaultQueryConfig },
});
