"use client";

import React, { useEffect, createContext, useContext, useCallback, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { Experiment, ExperimentClient, Variant } from "@amplitude/experiment-js-client";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { BaseEvent, EventType } from "@/types/events";
import { usePathname } from "next/navigation";
const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
const AMPLITUDE_SECRET_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_SECRET_KEY;
const ENABLE_AMPLITUDE = process.env.NEXT_PUBLIC_ENABLE_AMPLITUDE;
const EXPERIMENT_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENT_KEY;
export type ExperimentFlag = {
  flagKey: string;
  variant: Variant;
};
export type VariantsResponse = Record<string, Variant>;
type Primitive = string | number | boolean | null | undefined;
type EventValue = Primitive | {
  [key: string]: EventValue;
} | EventValue[];
type AmplitudeContextType = {
  trackEvent: <T extends BaseEvent>(eventName: EventType<T>, eventProperties?: Record<string, EventValue>) => void;
  experiment?: ExperimentClient;
  setUserProperties: (userProperties: Record<string, any>) => void;
  getVariant: (flagKey: string) => Variant | null;
  getAllFlags: () => VariantsResponse | null;
  fetchFlags: (userId?: string, userProperties?: Record<string, any>) => Promise<void>;
  evaluateFlag: <T>(flagKey: string, defaultValue: T) => T;
  trackExposure: (flagKey: string) => void;
  flagsFetched: boolean;
};
const AmplitudeContext = createContext<AmplitudeContextType>({
  trackEvent: () => {},
  experiment: undefined,
  setUserProperties: () => {},
  getVariant: () => null,
  getAllFlags: () => null,
  fetchFlags: async () => {},
  evaluateFlag: (_, defaultValue) => defaultValue,
  trackExposure: () => {},
  flagsFetched: false
});
export const AmplitudeContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [experiment, setExperiment] = useState<ExperimentClient>();
  const [variants, setVariants] = useState<VariantsResponse | null>(null);
  const [flagsFetched, setFlagsFetched] = useState(false);
  const path = usePathname();
  const trackEvent = useCallback(<T extends BaseEvent,>(eventName: EventType<T>, eventProperties?: Record<string, EventValue>) => {
    if (ENABLE_AMPLITUDE && AMPLITUDE_API_KEY) {
      amplitude.track(eventName, {
        ...eventProperties,
        page: path
      });
    }
  }, [path]);
  const setUserProperties = useCallback((userProperties: Record<string, any>) => {
    if (ENABLE_AMPLITUDE && AMPLITUDE_API_KEY) {
      const identifyObj = new amplitude.Identify();
      Object.entries(userProperties).forEach(([key, value]) => {
        identifyObj.set(key, value);
      });
      amplitude.identify(identifyObj);
    }
  }, []);
  const getVariant = useCallback((flagKey: string): Variant | null => {
    if (!experiment || !variants) return null;
    return variants[flagKey] || null;
  }, [experiment, variants]);
  const getAllFlags = useCallback((): VariantsResponse | null => {
    return variants;
  }, [variants]);
  const fetchFlags = useCallback(async (userId?: string, userProperties?: Record<string, any>) => {
    if (!experiment) return;
    try {
      if (userId) {
        amplitude.setUserId(userId);
        if (userProperties) {
          // Create an identify object to set user properties
          const identifyObj = new amplitude.Identify();

          // Add user properties to the identify object
          Object.entries(userProperties).forEach(([key, value]) => {
            identifyObj.set(key, value);
          });

          // Send the identify call to Amplitude
          amplitude.identify(identifyObj);
        }
      }

      // Fetch variants from Amplitude Experiment
      await experiment.fetch({
        user_id: userId,
        user_properties: userProperties
      });

      // Get all flags from the experiment
      const fetchedVariants = experiment.all();
      setVariants(fetchedVariants);
    } catch (error) {
      console.error("Error fetching experiment flags:", error);
    }
  }, [experiment]);

  // Evaluate a flag and return its value or a default value
  const evaluateFlag = useCallback<AmplitudeContextType['evaluateFlag']>((flagKey, defaultValue) => {
    if (!experiment || !variants) return defaultValue;
    const variant = variants[flagKey];
    if (!variant || !variant.value) return defaultValue;

    // If the type of the variant value is the same as the default value, return it
    if (typeof variant.value === typeof defaultValue) {
      return variant.value as any;
    }

    // If the variant value is a string and the default value is an object, try to parse it
    try {
      if (typeof variant.value === 'string' && typeof defaultValue === 'object') {
        return JSON.parse(variant.value);
      }
      return defaultValue;
    } catch (e) {
      console.error(`Error parsing variant value for flag ${flagKey}:`, e);
      return defaultValue;
    }
  }, [experiment, variants]);

  // Register exposure of a flag to the experiment
  const trackExposure = useCallback((flagKey: string) => {
    if (!experiment) return;
    experiment.exposure(flagKey);
  }, [experiment]);
  useEffect(() => {
    if (ENABLE_AMPLITUDE && AMPLITUDE_API_KEY && AMPLITUDE_SECRET_KEY) {
      const sessionReplayTracking = sessionReplayPlugin({
        forceSessionTracking: true,
        sampleRate: 0.1
      });
      amplitude.add(sessionReplayTracking);
      let deviceId = amplitude.getDeviceId();
      if (!deviceId) {
        deviceId = crypto.randomUUID();
      }
      amplitude.init(AMPLITUDE_API_KEY, deviceId, {
        autocapture: {
          elementInteractions: false,
          sessions: true,
          formInteractions: false,
          pageViews: true
        }
      });
      amplitude.setUserId(deviceId);
      if (EXPERIMENT_KEY) {
        const experimentClient = Experiment.initializeWithAmplitudeAnalytics(EXPERIMENT_KEY);
        setExperiment(experimentClient);
        const initialFetch = async () => {
          try {
            await experimentClient.fetch({
              user_id: deviceId
            });
            const initialVariants = experimentClient.all();
            setVariants(initialVariants);
            setFlagsFetched(true);
          } catch (error) {
            console.error("Error fetching experiment flags:", error);
          }
        };
        initialFetch();
      } else {
        console.error("No Amplitude Experiment key found");
      }
    }
  }, []);
  return <AmplitudeContext.Provider value={{
    trackEvent,
    experiment,
    setUserProperties,
    getVariant,
    getAllFlags,
    fetchFlags,
    evaluateFlag,
    trackExposure,
    flagsFetched
  }} data-sentry-element="unknown" data-sentry-component="AmplitudeContextProvider" data-sentry-source-file="AmplitudeContext.tsx">
            {children}
        </AmplitudeContext.Provider>;
};
export const useAmplitudeContext = (): AmplitudeContextType => {
  const context = useContext(AmplitudeContext);
  if (context === undefined) {
    throw new Error("useAmplitudeContext must be used within a AmplitudeContextProvider");
  }
  return context;
};
export const useFeatureFlag = <T,>(flagKey: string, defaultValue: T): T => {
  const {
    evaluateFlag,
    trackExposure
  } = useAmplitudeContext();
  useEffect(() => {
    // Register exposure of the flag when the component mounts
    trackExposure(flagKey);
  }, [flagKey, trackExposure]);
  return evaluateFlag(flagKey, defaultValue);
};
export default AmplitudeContextProvider;